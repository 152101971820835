import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggle', 'visibility']

  toggle() {
    if (this.toggleTarget.dataset.private == 'false') {
      this.toggleTarget.classList.remove('bg-gray-200');
      this.toggleTarget.classList.add('bg-primary-default');
      this.toggleTarget.dataset.private = 'true';
      this.toggleTarget.dataset.params = 'private=true';
      this.toggleTarget.children[0].classList.remove('translate-x-0');
      this.toggleTarget.children[0].classList.add('translate-x-5');
      this.visibilityTarget.innerText = 'Private';
    } else if (this.toggleTarget.dataset.private == 'true') {
      this.toggleTarget.classList.remove('bg-primary-default');
      this.toggleTarget.classList.add('bg-gray-200');
      this.toggleTarget.dataset.private = 'false';
      this.toggleTarget.dataset.params = 'private=false';
      this.toggleTarget.children[0].classList.remove('translate-x-5');
      this.toggleTarget.children[0].classList.add('translate-x-0');
      this.visibilityTarget.innerText = 'Public';
    }
  }
}
