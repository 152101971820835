import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ['modal', 'background', 'button', 'label', 'form', 'input'];

  openModal(event) {
    const bucketName = event.target.getAttribute('data-empty-name');
    this.labelTarget.innerHTML = `Permanently delete all objects in bucket "${bucketName}"?`;
    this.formTarget.action = `/buckets/${bucketName}/empty`;
    this.inputTarget.value = '';
    enter(this.modalTarget);
    document.getElementById('emptyBucketInput').focus();
  }

  closeModal() {
    this.inputTarget.value = ''
    this.verifyEmpty();
    leave(this.modalTarget);
  }

  closeBackground(event) {
    if (event.target === this.backgroundTarget) this.closeModal()
  }

  verifyEmpty() {
    if (this.inputTarget.value == 'permanently delete') {
      this.buttonTarget.classList.remove('opacity-50');
      this.buttonTarget.classList.remove('cursor-not-allowed');
      this.buttonTarget.classList.remove('cursor-not-allowed');
      this.buttonTarget.disabled = false;
    } else {
      this.buttonTarget.classList.add('opacity-50');
      this.buttonTarget.classList.add('cursor-not-allowed');
      this.buttonTarget.disabled = true;
    }
  }
}
