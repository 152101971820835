import { Controller } from '@hotwired/stimulus';
import ClipboardJS from 'clipboard';
import tippy from 'tippy.js';
import { toggle } from 'el-transition';

export default class extends Controller {
  static targets = ['s3EndpointCopy', 'psaEndpointCopy', 'psaAuthTokenDisplay', 'psaAuthTokenSection', 'psaAuthTokenCopy', 'psaAuthBucketName', 'modal', 'modalBackground', 'input', 'button'];

  initialize() {
    this.initPopup();

    const authTokenDisplayTarget = this.psaAuthTokenDisplayTarget
    const psaAuthTokenSectionTarget = this.psaAuthTokenSectionTarget
    const psaAuthTokenCopyTarget = this.psaAuthTokenCopyTarget
    this.psaAuthBucketNameTarget.addEventListener('change', (event) => {
      if (event.target.value.length > 0) {
        const psaAuthToken = btoa(`${event.target.dataset.key}:${event.target.dataset.secret}:${event.target.value}`);
        authTokenDisplayTarget.value = psaAuthToken;
        psaAuthTokenCopyTarget.dataset.clipboardText = psaAuthToken;
        psaAuthTokenSectionTarget.classList.remove('hidden');
      } else {
        psaAuthTokenSectionTarget.classList.add('hidden');
        psaAuthTokenCopyTarget.dataset.clipboardText = "";
        authTokenDisplayTarget.value = "";
      }
    })
  }

  initPopup() {
    if (this.hasS3EndpointCopyTarget) {
      tippy(this.s3EndpointCopyTarget, {
        content: 'Copied!',
        placement: 'bottom',
        trigger: 'click',
        onShow(instance) {
          setTimeout(() => {
            instance.hide();
          }, 1000);
        }
      })
    }

    if (this.hasPsaEndpointCopyTarget) {
      tippy(this.psaEndpointCopyTarget, {
        content: 'Copied!',
        placement: 'bottom',
        trigger: 'click',
        onShow(instance) {
          setTimeout(() => {
            instance.hide();
          }, 1000);
        }
      })
    }

    if (this.hasPsaAuthTokenCopyTarget) {
      tippy(this.psaAuthTokenCopyTarget, {
        content: 'Copied!',
        placement: 'bottom',
        trigger: 'click',
        onShow(instance) {
          setTimeout(() => {
            instance.hide();
          }, 1000);
        }
      })
    }
  }

  s3EndpointCopy() {
    new ClipboardJS('#s3_endpoint');
  }

  psaEndpointCopy() {
    new ClipboardJS('#psa_endpoint');
  }

  psaAuthTokenCopy() {
    new ClipboardJS('#psa_auth_token');
  }

  toggle() {
    this.inputTarget.value = '';
    toggle(this.modalTarget);
    this.inputTarget.focus();
  }

  verify() {
    if (this.inputTarget.value == 'rotate my keys') {
      this.buttonTarget.classList.remove('opacity-50');
      this.buttonTarget.classList.remove('cursor-not-allowed');
      this.buttonTarget.classList.remove('cursor-not-allowed');
      this.buttonTarget.disabled = false;
    } else {
      this.buttonTarget.classList.add('opacity-50');
      this.buttonTarget.classList.add('cursor-not-allowed');
      this.buttonTarget.disabled = true;
    }
  }

  closeModalBackground(event) {
    if (event.target === this.modalBackgroundTarget) this.toggle();
  }
}
