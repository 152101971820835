import { Controller } from '@hotwired/stimulus';
import ClipboardJS from 'clipboard';
import tippy from 'tippy.js';
import { toggle } from 'el-transition';

export default class extends Controller {
  static targets = ['createNameModal', 'createNameBackground', 'updateNameModal', 'updateNameBackground', 'label', 'labelInput', 'cidInput', 'privateKeyInput', 'cidUpdateInput', 'cidShare', 'networkKeyShare', 'updateLabel'];

  initialize() {
    if (this.hasNetworkKeyShareTarget) {
      Array.prototype.forEach.call(this.networkKeyShareTargets, targetElement => {
        new ClipboardJS(targetElement);
        tippy(targetElement, {
          content: 'IPNS Key Copied!',
          placement: 'bottom',
          trigger: 'click',
          onShow(instance) {
            setTimeout(() => {
              instance.hide();
            }, 1000);
          },
        })
      });
    };
    if (this.hasCidShareTarget) {
      Array.prototype.forEach.call(this.cidShareTargets, targetElement => {
        new ClipboardJS(targetElement);
        tippy(targetElement, {
          content: 'IPFS CID Copied!',
          placement: 'bottom',
          trigger: 'click',
          onShow(instance) {
            setTimeout(() => {
              instance.hide();
            }, 1000);
          }
        })
      });
    };
  }

  toggleCreateNameModal() {
    this.labelInputTarget.value = '';
    this.cidInputTarget.value = '';
    this.privateKeyInputTarget.value = '';
    toggle(this.createNameModalTarget);
    this.labelInputTarget.focus();
  }

  closeCreateNameBackground(event) {
    if (event.target === this.createNameBackgroundTarget) this.toggleCreateNameModal();
  }

  toggleUpdateNameModal(event) {
    this.cidUpdateInputTarget.value = '';
    if (event) {
      const label = event.target.getAttribute('data-name-label');
      this.labelTarget.innerText = label;
      this.updateLabelTarget.value = label;
    }
    toggle(this.updateNameModalTarget);
    this.cidUpdateInputTarget.focus();
  }

  closeUpdateNameBackground(event) {
    if (event.target === this.updateNameBackgroundTarget) this.toggleUpdateNameModal();
  }
}
