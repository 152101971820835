import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['toggleNotPro', 'toggle', 'visibility']

  initialize() {
    if (this.hasToggleNotProTarget) {
      this.toggleNotPro();
    };
  }

  toggleNotPro() {
    if (this.toggleNotProTarget.dataset.private == 'true') {
      var message = 'Public buckets require a paid subscription.'
    } else if (this.toggleNotProTarget.dataset.private == 'false') {
      var message = 'This is a grandfathered free public bucket. Contact support to make private.'
    }
    tippy(this.toggleNotProTarget, {
      content: message,
      placement: 'bottom',
      trigger: 'click',
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 4000);
      }
    })
  }

  toggle() {
    if (this.toggleTarget.dataset.private == 'false') {
      this.toggleTarget.classList.remove('bg-gray-200');
      this.toggleTarget.classList.add('bg-primary-default');
      this.toggleTarget.dataset.private = 'true';
      this.toggleTarget.dataset.params = 'private=true';
      this.toggleTarget.children[0].classList.remove('translate-x-0');
      this.toggleTarget.children[0].classList.add('translate-x-5');
      this.visibilityTarget.innerText = 'Private';
    } else if (this.toggleTarget.dataset.private == 'true') {
      this.toggleTarget.classList.remove('bg-primary-default');
      this.toggleTarget.classList.add('bg-gray-200');
      this.toggleTarget.dataset.private = 'false';
      this.toggleTarget.dataset.params = 'private=false';
      this.toggleTarget.children[0].classList.remove('translate-x-5');
      this.toggleTarget.children[0].classList.add('translate-x-0');
      this.visibilityTarget.innerText = 'Public';
    }
  }
}
