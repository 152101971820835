import { Controller } from '@hotwired/stimulus';
import { toggle } from 'el-transition';
import ClipboardJS from "clipboard";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ['modal', 'background', 'input', 'ipfsShare'];

  initialize() {
    if (this.hasIpfsShareTarget) {
      Array.prototype.forEach.call(this.ipfsShareTargets, targetElement => {
        new ClipboardJS(targetElement);
        tippy(targetElement, {
          content: 'Bucket CID Copied!',
          placement: 'bottom',
          trigger: 'click',
          onShow(instance) {
            setTimeout(() => {
              instance.hide();
            }, 1000);
          }
        })
      });
    }
  }

  toggle(event) {
    this.inputTarget.value = '';
    toggle(this.modalTarget);
    this.inputTarget.focus();

    const importElements = document.getElementById('source');
    if (importElements !== null) {
      document.getElementById('source').addEventListener('change', function (event) {
        if (event.target.value === 'none') {
          document.getElementById('api_token').value = null;
          document.getElementById('api_token_input_group').classList.add('hidden');
        } else {
          document.getElementById('api_token').value = null;
          document.getElementById('api_token_input_group').classList.remove('hidden');
        }
      })
    }
  }

  closeBackground(event) {
    if (event.target === this.backgroundTarget) this.toggle()
  }
}
