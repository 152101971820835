import { Controller } from '@hotwired/stimulus';
import { toggle } from 'el-transition';

export default class extends Controller {
  static targets = ['addCustomDomainModal', 'addCustomDomainBackground', 'removeCustomDomainModal', 'removeCustomDomainBackground', 'createGatewayModal', 'createGatewayBackground', 'gatewayAuthorizationModal', 'gatewayAuthorizationBackground'];

  toggleAddCustomDomainModal(event) {
    document.getElementById('domain_input').value = '';
    document.getElementById('custom_domain_value').innerText = '';
    if (event) {
      const gatewayName = event.target.getAttribute('data-gateway-name');
      document.getElementById('update_gateway_name').value = gatewayName;
      document.getElementById('gateway_record_value').innerText = gatewayName;
      document.getElementById('domain_input').addEventListener('keyup', function (event) {
        document.getElementById('custom_domain_value').innerText = event.target.value;
      })
    }
    toggle(this.addCustomDomainModalTarget);
    document.getElementById('domain_input').focus();
  }

  closeAddCustomDomainBackground(event) {
    if (event.target === this.addCustomDomainBackgroundTarget) this.toggleAddCustomDomainModal();
  }

  toggleRemoveCustomDomainModal(event) {
    if (event) {
      const gatewayName = event.target.getAttribute('data-gateway-name');
      document.getElementById('remove_gateway_name').value = gatewayName;
    }
    toggle(this.removeCustomDomainModalTarget);
  }

  closeRemoveCustomDomainBackground(event) {
    if (event.target === this.removeCustomDomainBackgroundTarget) this.toggleRemoveCustomDomainModal();
  }

  toggleCreateGatewayModal() {
    document.getElementById('gateway_name').value = '';
    toggle(this.createGatewayModalTarget);
    document.getElementById('gateway_name').focus();
    const privacyElements = document.getElementById('privacy');
    if (privacyElements !== null) {
      document.getElementById('privacy').addEventListener('change', function (event) {
        if (event.target.value === '1') {
          document.getElementById('bucket_name').classList.remove('disabled');
          document.getElementById('bucket_input_group').classList.remove('hidden');
        } else {
          document.getElementById('bucket_name').classList.add('disabled');
          document.getElementById('bucket_input_group').classList.add('hidden');
        }
      })
    }
  }

  closeCreateGatewayBackground(event) {
    if (event.target === this.createGatewayBackgroundTarget) this.toggleCreateGatewayModal();
  }

  toggleGatewayAuthorizationModal() {
    toggle(this.gatewayAuthorizationModalTarget);
  }

  closeGatewayAuthorizationBackground(event) {
    if (event.target === this.gatewayAuthorizationBackgroundTarget) this.toggleGatewayAuthorizationModal();
  }
}
