import { Controller } from '@hotwired/stimulus';
import ClipboardJS from 'clipboard';
import tippy from 'tippy.js';
import { toggle } from 'el-transition';

export default class extends Controller {
  static targets = ['newFolderModal', 'newFolderBackground', 'pinByCIDModal', 'pinByCIDBackground', 'gatewayRootModal', 'gatewayRootBackground','copyAssetModal', 'copyAssetBackground','moveAssetModal', 'moveAssetBackground','renameAssetModal', 'renameAssetBackground', 'uploadMenu', 'ipfsShare', 'uploadButton', 'share'];

  initialize() {
    if (this.hasShareTarget) {
      Array.prototype.forEach.call(this.shareTargets, targetElement => {
        new ClipboardJS(targetElement);
        tippy(targetElement, {
          content: 'Link Copied!',
          placement: 'bottom',
          trigger: 'click',
          onShow(instance) {
            setTimeout(() => {
              instance.hide();
            }, 1000);
          },
        })
      });
    };
    if (this.hasIpfsShareTarget) {
      Array.prototype.forEach.call(this.ipfsShareTargets, targetElement => {
        new ClipboardJS(targetElement);
        tippy(targetElement, {
          content: 'IPFS CID Copied!',
          placement: 'bottom',
          trigger: 'click',
          onShow(instance) {
            setTimeout(() => {
              instance.hide();
            }, 1000);
          }
        })
      });
    };
  }

  nextPage() {
    var nextContinuationToken = this.data.get("nextContinuationToken");
    window.location.href = window.location.href.split('?')[0] + '?continuation-token=' + nextContinuationToken;
  }

  previousPage() {
    window.history.back();
  }

  toggleNewFolderModal() {
    document.getElementById('key').value = '';
    toggle(this.newFolderModalTarget);
    document.getElementById('key').focus();
  }

  closeNewFolderBackground(event) {
    if (event.target === this.newFolderBackgroundTarget) this.toggleNewFolderModal();
  }

  uploadToggle() {
    this.uploadMenuTarget.classList.toggle('hidden');
  }

  togglePinByCIDModal() {
    document.getElementById('cid').value = '';
    document.getElementById('name').value = '';
    toggle(this.uploadMenuTarget);
    toggle(this.pinByCIDModalTarget);
    document.getElementById('cid').focus();
  }

  closePinByCIDBackground(event) {
    if (event.target === this.pinByCIDBackgroundTarget) this.togglePinByCIDModal();
  }

  hideUploadMenu(event) {
    if (event && (this.uploadMenuTarget.contains(event.target) || this.uploadButtonTarget.contains(event.target))) {
      return;
    }
    this.uploadMenuTarget.classList.add('hidden');
  }

  toggleGatewayRootModal(event) {
    if (event) {
      const assetName = event.target.getAttribute('data-asset-name');
      const bucketName = event.target.getAttribute('data-bucket-name');
      document.getElementById('gateway_root_asset_name').value = assetName;
      document.getElementById('gateway_root_bucket_name').value = bucketName;
    }
    toggle(this.gatewayRootModalTarget);
  }

  closeGatewayRootBackground(event) {
    if (event.target === this.gatewayRootBackgroundTarget) this.toggleGatewayRootModal();
  }

  toggleCopyAssetModal(event) {
    if (event) {
      const assetName = event.target.getAttribute('data-asset-name');
      document.getElementById('copy_asset_source_name').value = assetName
      document.getElementById('copy_asset_name').value = assetName
    }
    toggle(this.copyAssetModalTarget);
  }

  closeCopyAssetBackground(event) {
    if (event.target === this.copyAssetBackgroundTarget) this.toggleCopyAssetModal();
  }

  toggleMoveAssetModal(event) {
    if (event) {
      const assetName = event.target.getAttribute('data-asset-name');
      document.getElementById('move_asset_name').value = assetName
    }
    toggle(this.moveAssetModalTarget);
  }

  closeMoveAssetBackground(event) {
    if (event.target === this.moveAssetBackgroundTarget) this.toggleMoveAssetModal();
  }

  toggleRenameAssetModal(event) {
    if (event) {
      const assetName = event.target.getAttribute('data-asset-name');
      document.getElementById('rename_asset_original_name').value = assetName
      document.getElementById('rename_asset_name').value = assetName
    }
    toggle(this.renameAssetModalTarget);
  }

  closeRenameAssetBackground(event) {
    if (event.target === this.renameAssetBackgroundTarget) this.toggleRenameAssetModal();
  }
}
