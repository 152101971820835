import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import ClipboardJS from 'clipboard';
import tippy from 'tippy.js';
import { toggle } from 'el-transition';

export default class extends Controller {
  static targets = ['metadataBackground', 'ipfsShare', 'share', 'modal'];

  initialize() {
    if (this.hasShareTarget) {
      Array.prototype.forEach.call(this.shareTargets, targetElement => {
        new ClipboardJS(targetElement);
        tippy(targetElement, {
          content: 'Link Copied!',
          placement: 'bottom',
          trigger: 'click',
          onShow(instance) {
            setTimeout(() => {
              instance.hide();
            }, 1000);
          },
        })
      });
    };
    if (this.hasIpfsShareTarget) {
      Array.prototype.forEach.call(this.ipfsShareTargets, targetElement => {
        new ClipboardJS(targetElement);
        tippy(targetElement, {
          content: 'IPFS CID Copied!',
          placement: 'bottom',
          trigger: 'click',
          onShow(instance) {
            setTimeout(() => {
              instance.hide();
            }, 1000);
          }
        })
      });
    };
  }

  open() {
    Rails.ajax({
      type: "post",
      url: window.location.href.split('?')[0] + '?action=GeneratePresignedUrl&response-content-disposition=inline',
      success: function(response) {
        window.location.href = response.presignedUrl;
      },
      error: function() {
        alert('Error generating presigned URL');
      }
    })
  }

  download() {
    Rails.ajax({
      type: "post",
      url: window.location.href.split('?')[0] + '?action=GeneratePresignedUrl&response-content-disposition=attachment',
      success: function(response) {
        window.location.href = response.presignedUrl;
      },
      error: function() {
        alert('Error generating presigned URL');
      }
    })
  }

  closeMetadata(event) {
    if (event.target === this.metadataBackgroundTarget) this.toggleMetadataModal();
  }

  toggleMetadataModal() {
    toggle(this.modalTarget);
  }
}
