import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ['modal', 'background'];

  openModal(event) {
    const bucketName = event.target.getAttribute('data-bucket-name')
    document.getElementById('gateway_restricted_bucket_name').value = bucketName;
    enter(this.modalTarget);
    document.getElementById('gateway_name').focus();
  }

  closeModal() {
    leave(this.modalTarget);
  }

  closeBackground(event) {
    if (event.target === this.backgroundTarget) this.closeModal()
  }
}
