import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ['mainMenu', 'mainMenu1', 'mainMenu2', 'mainMenu3'];

  toggleMenu() {
    if (this.mainMenuTarget.classList.contains('hidden')) {
      enter(this.mainMenuTarget)
      enter(this.mainMenu1Target)
      enter(this.mainMenu2Target)
      enter(this.mainMenu3Target)
    } else {
      leave(this.mainMenu1Target)
      leave(this.mainMenu2Target)
      leave(this.mainMenu3Target).then(() => {
        leave(this.mainMenuTarget)
      });
    }
  }
}
